<script setup lang="ts">
import type { Schemas } from "#shopware";
import { getTranslatedProperty, getProductRoute } from "@shopware/helpers";

const props = defineProps<{ product: Schemas["Product"] }>();
const { product } = toRefs(props);

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const { unitPrice, price, isListPrice } = useProductPrice(product);

const productPath = computed(() => formatLink(getProductRoute(product.value)));
</script>
<template>
  <NuxtLink
    :to="productPath"
    class="flex items-center gap-3 hover:bg-scheppach-primary-100 p-2 rd duration-200"
  >
    <SharedMediaImage
      :media="product.cover?.media"
      class="object-contain size-15 mx-auto rd"
      :alt="$t('product.image')"
    />
    <div class="grow flex flex-col min-w-0 flex-shrink gap-1">
      <div class="truncate text-sm font-700 c-scheppach-shades-100">
        {{ getTranslatedProperty(product, "name") }}
      </div>
      <div class="flex gap-sm items-baseline">
        <SharedPrice
          :value="unitPrice"
          class="text-4 font-700 text-scheppach-primary-500"
        >
          <template #afterPrice>*</template>
        </SharedPrice>
        <SharedPrice
          v-if="isListPrice"
          :value="price?.listPrice?.price"
          class="text-3 c-scheppach-neutral-500 custom-line-through"
        >
          <template #afterPrice>*</template>
        </SharedPrice>
      </div>
    </div>
    <NuxtLink
      :to="productPath"
      class="size-10 bg-scheppach-primary-700 rd flex-shrink-0"
    >
      <div class="w-5 h-full c-white i-sl-arrow-right mx-auto"></div>
    </NuxtLink>
  </NuxtLink>
</template>
